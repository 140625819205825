@font-face {
  font-family: 'icons';
  src: url('/assets/icomoon/fonts/icomoon.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: currentColor;
}


.icon-pause:before {
  content: "\e905";
}

.icon-airplane:before {
  content: "\e900";
}

.icon-cruks:before {
  content: "\e901";
}

.icon-email:before {
  content: "\e902";
}

.icon-passport:before {
  content: "\e903";
}

.icon-phone:before {
  content: "\e904";
}

@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';
@import '@material/theme/theme';
/* You can add global styles to this file, and also import other style files */
@import "theme/theme";
@import "theme/component-overrides.scss";

html, body { height: 100%; }
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.mat-error {
    color: #f44336;
}

input:focus, button:focus {
    outline: none;
}

//input:-webkit-autofill,
//input:-internal-autofill-selected
//{
//    & {
//        -webkit-box-shadow: 0 0 0px 1000px #F6F7F9 inset;
//        background-color: #F6F7F9;
//    }
//}

.button-text {
    color: #232d3a;
}

.icon {
    &-check {
        color: green;
    }

    &-clear {
        color: red;
    }

    &-help {
        color: cornflowerblue;
    }

    &-warning {
        color: #ffc400;
    }

    &-info {
        color: gray;
    }
}

.button-text {
    color: #232d3a;
}

.mat-mdc-list-base {
    &.mat-mdc-list-base .mat-mdc-list-item .mat-list-text>*, &.mat-list-base .mat-list-option .mat-list-text>* {
        font-size: 1rem;
    }
}

.ng-invalid.ng-dirty.ng-touched {
    @apply border-danger;
}

.mat-mdc-tooltip.tooltip {
    font-size: 1rem;
    max-width: 75vw;
    padding: 1rem;
    line-height: 1.25rem;
}

@layer base {
    #app-root {

        hr {
            @apply text-gray-darker;
        }

        .mat-mdc-menu-item {
            @apply flex items-center;
        }

        .mat-typography.mat-typography {
            h1, h2, h3, h4, p {
                @apply m-0;
            }
        }

        .pulse {
            @apply absolute block top-0 left-0 right-0 bottom-0 rounded-full border border-double;
            @apply border-seafoam-disabled;
        }

        h1 {
            @apply text-h1;
        }

        h2 {
            @apply text-h2;
        }

        .badge:before {
            @apply text-seafoam;
        }

        .nav-active {
            @apply text-seafoam-disabled;

            .badge {
                @apply bg-seafoam-disabled;

                &:before {
                    @apply border-seafoam-disabled;
                }
            }
        }

        .mat-input-date-picker {
            display: block !important;
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
            .mat-form-field-flex {
                padding: 0.35rem;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
            .mat-form-field-label-wrapper {
                top: -1.35rem;
                left: 0.5rem;
            }
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-form-field-infix {
            @apply p-0 border-0 pl-2;

            input {
                @apply text-p;
            }
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
        .mat-form-field-wrapper {
            @apply pb-0;
        }

        .bubble {
            @apply bg-white h-22 shadow-unselected rounded-2xl mx-4 w-auto mb-8 transition duration-300 h-22;

            &.selected {
                @apply sticky top-46 z-10 bottom-8;
            }

            &.selected.allowed {
                @apply shadow-success border border-success;
            }

            &.selected.denied {
                @apply shadow-error border border-danger;
            }
            /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
            .mat-list-item-content {
                @apply pl-2 pr-4 py-1;
            }
        }

        .mat-mdc-list-base {
            @apply pt-0;

            &.mat-mdc-selection-list {
                display: inherit;
            }
        }

        .date-range-input-form-clear {
            position: absolute;
            top: 0.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
            background-color: rgba(246, 247, 249, var(--tw-bg-opacity));
            width: 3rem;
            height: 3rem;
            cursor: pointer;
            right: 14px;

            svg {
                fill: gray;
            }
        }
    }

}

@layer components {
    table.themed-table {
        td.mat-mdc-cell {
            @apply p-2;
        }
        tr:not([mat-header-row]) {
            @apply transition-colors hover:bg-gray-border;
        }
    }
}

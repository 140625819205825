@import "variables";

.mdc-notched-outline__trailing, .mdc-notched-outline__notch, .mdc-notched-outline__leading {
    border-width: 0 !important;
    outline: none !important;
}

.mat-mdc-dialog-component-host > div {
    padding: 1rem !important;
}

.mdc-tab {
    flex-grow: 0 !important;
    min-width: 160px !important;
}

.mat-mdc-text-field-wrapper {
    @apply bg-input-background rounded border border-input-border items-center;
}

// Resets are needed after upgrading from angular material 14 to 15
// TODO Find another way to override these values
@mixin custom-theme-overrides() {
    .mat-mdc-form-field {
        background-color: rgba(246,247,249,var(--tw-bg-opacity)) !important;
        max-height: 52px !important;
    }

    .mat-mdc-form-field-subscript-wrapper {
        display: none !important;
    }

    .mat-mdc-tab-list {
        padding-bottom: 16px !important;
    }
    .mdc-tab  {
        font-family: map-get($vb-app-typography, "$headline-3") !important;
        font-size: 14px !important;
        font-weight: 500 !important;
    }
}

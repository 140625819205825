.vertical-tabs {
  height: 100%;

  .mat-mdc-tab-group {
    flex-direction: row;
    height: 100%;
    margin-top: 16px;
    border-top: 2px solid theme('colors.gray.border');
  }

  .mat-mdc-tab-header {
    border-bottom: none;
  }

  .mat-mdc-tab-label-container {
    margin-right: 8px;
    border-right: 2px solid theme('colors.gray.border');
  }

  .mat-mdc-tab-header-pagination {
    display: none;
  }

  .mat-mdc-tab-labels {
    flex-direction: column;
  }

  .mat-mdc-tab-label {
    justify-content: left;
    padding: 0;
    border-bottom: 2px solid theme('colors.gray.border');
  }

  .mat-mdc-ink-bar {
    display: none;
  }

  .mat-mdc-tab-body-wrapper {
    flex: 1 1 auto;
  }
}

.horizontal-tabs {

  .mat-mdc-tab-group {
    flex-direction: initial;
    display: initial;
    height: 100%;
  }

  .mat-mdc-tab-labels {
    flex-direction: initial;
  }

  .mat-mdc-ink-bar {
    display: initial;
  }

  .mat-mdc-tab-body-wrapper {
    flex: initial;
  }

  .mat-mdc-tab-label-container {
    border-right: unset;
  }
}